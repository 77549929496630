<template>
  <v-card :color="theme ? '#2F2F2F' :'#E7E5E5'">
    <div class="text-center">
      <i class="fas fa-key fa-3x mt-10 primary-blue-light" :class="{'primary-blue-dark': theme}"></i>
    </div>

    <v-card-title>
      <v-spacer></v-spacer>
      <span class="font-weight-bold">
        Forgot Password
      </span>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text class="text-center">
        <span class="mx-11">
          Enter your email address in the form bellow and we will send you further instructions on how to reset your password.
        </span>
      <div
          v-if="success"
          class="green--text"
          role="alert"
      >
        email with instructions to reset password has been sent
      </div>
      <div
          v-for="error in errors"
          class="red--text"
          role="alert"
          :key="error.code"
      >
        {{ error.message }}
      </div>
      <div class="mt-5 d-flex mx-10">
        <v-text-field
            v-model="email"
            placeholder="Email Adress"
            solo
            class="rounded-lg"
            :background-color="theme ? 'black' : ''"
        >
        </v-text-field>
        <v-btn :color="primaryBlue" dark :class="{'black--text': theme }" class="pa-6 rounded-lg ml-3" large
               @click="requestToken"> Submit
        </v-btn>
      </div>
    </v-card-text>
    <v-card-actions class="py-6 nevermind-light" :class="{'nevermind-dark': theme }">
      <v-spacer></v-spacer>
      <span> Never mind.</span>
      <router-link to='/auth/login' class="ml-2">Sign in</router-link>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
import colorMxn from '@/mixins/color_mixin';
import gql from "graphql-tag";

export default {
  name: 'PasswordReset',
  mixins: [colorMxn],

  data() {
    return {
      errors: [],
      success: false,
      email: ''
    }
  },
  methods: {
    requestToken() {
      this.errors = []
      this.success = false
      let data_ = {
        email: this.email
      }
      let RESET_QUERY = gql`
        mutation($email: String!) {
          sendPasswordResetEmail(email: $email) {
            success
            errors
          }
        }
      `;
      this.$apollo.mutate({
        mutation: RESET_QUERY,
        variables: data_,
        update: (cache, {data}) => {
          try {
            let errors = data.sendPasswordResetEmail.errors;
            this.success = data.sendPasswordResetEmail.success;
            if (errors) {
              this.errors = errors.email;
            }
          } catch (e) {
            console.log(e);
          }
        },
      });
    },
  },
}
</script>

<style>
.nevermind-light {
  background: #D1D0D0;
}

.nevermind-dark {
  background: #07090D;
}
</style>